import React from "react"
import "./style.scss"
import mission from '../../assets/images/mission.png';
import team from '../../assets/images/team.png';
import shop from '../../assets/images/shop.png';
import raffle from '../../assets/images/raffle.png';


const Links = () => {
    return (
        <ul className="main-menu">
            <div className="col-lg-6 col-sm-12 menu-left links">
                <li name="mission">
                    <a href="https://meta-labs.space/mission">
                        <img src={mission} className="nav-image"/>
                    </a>                    
                </li>
                <li name="team">
                    <a href="https://meta-labs.space/team">
                        <img src={team} className="nav-image"/>
                    </a>                    
                </li>
            </div>
            <div className="col-lg-6 col-sm-12 menu-right links">
                <li name="videos" className="no-link">
                    <a href="https://meta-labs.space/shop">
                        <img src={shop} className="nav-image"/>
                    </a>                    
                </li>
                <li name="shop">
                    <a href="https://raffle.meta-labs.space/">
                        <img src={raffle} className="nav-image"/>
                    </a>
                </li>
            </div>
        </ul>
    )
}

export default Links