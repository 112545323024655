import React from "react"
import TopLeft from "./topLeft"
import Background from "./backgrond"
import Login from "./login"
import Links from "./links"
import Box1 from "./box1"
import Box2 from "./box2"
import Box3 from "./box3"
import Box4 from "./box4"
import Footer from "./footer"
import Loader from "../Loader"
import { Container, Col, Row } from "react-bootstrap"
import "./style.scss"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import jwt_decode from 'jwt-decode';
import {
    loginsuccess,
} from "../../redux/actions";


const Design = (props) => {
    const { metamaskConnected, account, setMetamaskConnnected } = props;

    const dispatch = useDispatch();
    const username = sessionStorage.getItem('token') ? jwt_decode(sessionStorage.getItem('token')).username : "";    

    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState(username);

    useEffect(() => {
        dispatch(
            loginsuccess({
                user: username,
            })
        );
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);    

    return (
        <div>
            {loading &&
                <Loader />
            }
            {!loading &&
                <div className="wrapper fade-in-animation">
                    <Background />
                    <div className="w100 row topSection">
                        <Col lg="6" xs="12">
                            <TopLeft />
                        </Col>
                        <Col lg="6" xs="12">
                            <Login 
                                setUserName={setUserId}
                            />
                        </Col>
                    </div>
                    <Links />
                    <Container className="w100">
                        <Row className="margin-top">
                            <Col lg="8" sm="12">
                                <Box1 />
                                <Box2 
                                    metamaskConnected={metamaskConnected}
                                    setMetamaskConnnected={setMetamaskConnnected}
                                    account={account}
                                    userId={userId}
                                />
                            </Col>
                            <Col lg="4" sm="12">
                                <Box3 />
                                <Box4 />
                            </Col>
                        </Row>
                        <Footer />
                    </Container>
                </div>
            }
        </div>
    )
}

export default Design;