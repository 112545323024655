import React from "react"
import piece1 from '../../assets/images/meta-piece1.png';
import piece2 from "../../assets/images/meta-piece2.png"
import piece3 from "../../assets/images/white_logo.png"
import piece4 from "../../assets/images/meta-piece4.png"
import piece5 from "../../assets/images/meta-piece3.png"


const Background = () => {
    return (
        <React.Fragment>
            <div className="background">
                <img className="piece1" src={piece1} />
                <img className="piece2" src={piece2} />
                <div className="piece3">
                    <a href="https://meta-labs.space"> <img src={piece3} /> </a>
                </div>
                <img className="piece4" src={piece4} />
                <img className="piece5" src={piece5} />
            </div>
        </React.Fragment>
    )
}

export default Background;