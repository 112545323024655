import {
  LOGIN_SUCCESS,
  LOGIN_OUT
} from "../shared/ActionTypes.js";

const INIT_STATE = {
  user: ""
};

const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      return {
        ...state,
        user: action.payload
      }
    }
    case LOGIN_OUT: {
      return {
        ...state,
        user: ""
      }
    }
    default:
      return state;
  }
};
export default authReducer;
