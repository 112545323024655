import React from "react"
import "./style.scss"
import { useEffect } from "react"
import { useDispatch } from "react-redux";

const Loader = (props) => {

    return (
        <div>
            <div className="loader-wrapper dark">
                <div className="spinner">
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                </div>
            </div>            
        </div>    
    )
}

export default Loader;