import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux";
import "./style.scss";
import axios from "axios"
import jwt_decode from 'jwt-decode';
import $ from "jquery"
import {
    loginsuccess,
    logout
} from "../../redux/actions";
import swal from 'sweetalert';
import { DatePicker } from "antd"
import 'antd/dist/antd.css';
const server_url = process.env.REACT_APP_SERVER_URL;

const Login = (props) => {
    const {setUserName} = props;
    
    const [flag, setFlag] = useState("login");
    const [params, setParams] = useState([]);
    const [userid, setUserid] = useState('');
    const username = sessionStorage.getItem('token') ? jwt_decode(sessionStorage.getItem('token')) : "";
    const verify = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    // const [startDate, setStartDate] = useState(new Date());

    console.log(verify)

    useEffect(() => {
        setUserid(username);
    }, []);



    const LoginPanel = () => {
        return (
            <div>
                <input onChange={handleChange} value={params['name']} name="name" className="form-control" placeholder="Email" />
                <input type="password" onChange={handleChange} value={params['password']} name="password" className="form-control" placeholder="Password" />
            </div>
        )
    }

    const onChange = (date, dateString) => {
        var value = params;
        value['birthday'] = dateString;
        setParams(value);
    };

    const SignUp = () => {
        return (
            <div>
                <input onChange={handleChange} value={params['name']} name="name" type="text" className="form-control" placeholder="Email" required />
                <input type="password" onChange={handleChange} value={params['password']} name="password" className="form-control" placeholder="Password" />
                <input type="password" onChange={handleChange} value={params['confirm_password']} name="confirm_password" className="form-control" placeholder="Confirm Password" />
                <DatePicker className="form-control" onChange={onChange} placeholder="Date of Birth… YYYY/MM/DD"/>
                {/* <DatePicker className="form-control" dateFormat="yyyy-mm-dd" selected={startDate} onChange={(date) => handleDate(date)} /> */}
                {/* <input type="date" datatype="yyyy-mm-dd" onChange={handleChange} value={params['birthday']} name="birthday" className="form-control" placeholder="Date of Birth" required /> */}
                <input onChange={handleChange} value={params['country']} name="country" className="form-control" placeholder="Country" required />
                <input onChange={handleChange} value={params['gender']} name="gender" className="form-control" placeholder="What is X/0?" required />
            </div>
        )
    }

    const LostPassword = () => {
        return (
            <div>
                <input onChange={handleChange} value={params['email']} name="email" className="form-control" placeholder="Email Addresss" />
            </div>
        )
    }
    const submit = () => {
        if (flag === "login") {
            if (params['name'] && params['password']) {
                axios.post(`${server_url}/userLogin`, {
                    name: params['name'],
                    password: params['password']
                }).then(res => {
                    if (res.data.flag === "success") {
                        console.log(res.data)
                        sessionStorage.setItem("token", res.data.data)
                        setUserid(jwt_decode(res.data.data).username)
                        setUserName(jwt_decode(res.data.data).username);
                        dispatch(
                            loginsuccess({
                                user: jwt_decode(res.data.data).username,
                            })
                        );
                        swal("Login", "You've logged in successfully.", "success");
                    }
                    if (res.data.flag === "email_not_verified") {
                        swal("Error", "Your email is not verified yet.", "error");
                    }
                    if (res.data.flag === "failed") {
                        swal("Error", "Credentials doesn't match! Please try again!", "error");
                    }
                    if (res.data.flag === "nouser") {
                        swal("Error", "Unregistered User! Please sign up to meta labs!", "error");
                    }
                }).catch(err => {
                    console.log(err)
                })
            }
            else { swal("You must input all values", "", "warning"); }
        }

        if (flag === "SignUp") {
            if (params['name'] && params['birthday'] && params['password'] && params['confirm_password'] && params['country'] && params['gender']) {
                if(params['password'] != params['confirm_password']) {
                    swal("Error", "Please input correct password!", "warning");
                    return;
                }
                axios.post(`${server_url}/userSignup`, {
                    name: params['name'],
                    password: params['password'],
                    birthday: params['birthday'],
                    country: params['country'],
                    gender: params['gender']
                }).then(res => {
                    console.log(res.data);
                    if (res.data.flag === 'success') {
                        swal("Success", "You've been registered successfully.", "success");
                    }
                    if (res.data.flag === 'exist') {
                        swal("Error", "Email is already exists.", "warning");
                    }
                }).catch(err => {
                    console.log(err)
                })
            }
            else { swal("You must input all values", "", "warning"); }
        }

        if (flag === "Lost") {
            if (params['email']) {
                console.log(123)
            }
            else { swal("You must input all values", "", "warning"); }
        }
    }

    const handleChange = (e) => {
        var value = params;
        value[e.target.name] = e.target.value;
        setParams(value);
    }

    const router = (value) => {
        setParams([]);
        $("input[name='name']").val("");
        $("input[name='password']").val("");
        if(value != flag) {
            setFlag(value);
        }
        else {
            submit();
        }
    }

    const log_out = () => {
        dispatch(logout())
        sessionStorage.removeItem("token");
        setUserid("")
        // router("login")
    }

    return (
        <div className="login form-group _center">
            {!userid &&
                <div className="w100 link">
                    {flag == "login" ? LoginPanel() : flag == "SignUp" ? SignUp() : LostPassword()}
                    <span>
                        <a onClick={() => router("login")}>Login</a>
                    </span>
                    <span> | </span>
                    <span>
                        <a onClick={() => router("SignUp")}>Sign up</a>
                    </span>
                    <span> | </span>
                    <span>
                        <a onClick={() => router("Lost")}>Lost Password</a>
                    </span>
                </div>
            }
            {userid &&
                <div className="w100 link">
                    <span>
                        <button className="btn btn-main" onClick={() => log_out()}>Logout</button>
                    </span>
                </div>
            }
        </div>
    )
}


export default Login