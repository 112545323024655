import React from "react"
import "./style.scss"

const Box4 = () => {
    return (
        <div className="box-container box4">
            <div className="message"> <span> #SpRox (38,023) </span> </div>
            <div className="message"> <span> #Meta_Labs_Space (37,233) </span> </div>
            <div className="message"> <span> #SpRox (38,023) </span> </div>
            <div className="message"> <span> #Meta_Labs_Space (37,233) </span> </div>
            <div className="message"> <span> #SpRox (38,023) </span> </div>
            <div className="message"> <span> #Meta_Labs_Space (37,233) </span> </div>
            <div className="message"> <span> #SpRox (38,023) </span> </div>
            <div className="message"> <span> #Meta_Labs_Space (37,233) </span> </div>
            <div className="message"> <span> #SpRox (38,023) </span> </div>
            <div className="message"> <span> #Meta_Labs_Space (37,233) </span> </div>
            <div className="message"> <span> #SpRox (38,023) </span> </div>
            <div className="message"> <span> #Meta_Labs_Space (37,233) </span> </div>
        </div>
    )
}

export default Box4