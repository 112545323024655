import React from "react"
import "./style.scss"

const Box1 = () => {
    return (
        <div className="box-container">
            <div className="statue"> <span className="date">Nov 20, 2022 :</span>  <span> private massages between menbers now working.</span> </div>
            <div className="statue"> <span className="date">Nov 20, 2022 :</span>  <span> private massages between menbers now working.</span> </div>
        </div>
    )
}

export default Box1