var Web3 = require("web3");

export const payWithETH = async (price, raffle_id, address) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        window.ethereum.enable();
    } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
    } else {
        window.alert(
            "Non-Ethereum browser detected. You should consider trying MetaMask!"
        );
    }
    var web3 = window.web3;
    
    const MetaLabsSpace = require("./MetaLabsSpace.json");
    const contractAddress = web3.utils.toChecksumAddress(process.env.REACT_APP_RAFFLE_CONTRACT);
    const contract = new web3.eth.Contract(MetaLabsSpace.abi, contractAddress);
    
    let e;
    try {
        var correctPrice = web3.utils.toBN(
            web3.utils.toWei(price, "ether").toString()
        );
    } catch (u) {
        console.log('err', u);
    }

    try {
        e = await contract.methods.depositETH(raffle_id).estimateGas({
            value: correctPrice,
            from: address
        })
    } catch (u) {
        return { success: false, type: 'estimategas' }
    }
    let d = await web3.eth.getGasPrice();
    let c;
    
    try {
        c = await contract.methods.depositETH(raffle_id).send({
            from: address,
            gas: parseInt(e),
            gasPrice: parseInt(1.2 * d),
            value: correctPrice,
            maxFeePerGas: null,
        })
    } catch (u) {
        return {success: false, type:"deposit"};
    }

    if(c){
        return {success: c.status, type:"deposit"};
    }
}

export const approvePayWithLDV = async (price, address) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        window.ethereum.enable();
    } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
    } else {
        window.alert(
            "Non-Ethereum browser detected. You should consider trying MetaMask!"
        );
    }
    var web3 = window.web3;
    
    const LDV = require("./LDV.json");
    const contractAddress = web3.utils.toChecksumAddress(process.env.REACT_APP_LDV_CONTRACT);
    const contract = new web3.eth.Contract(LDV.abi, contractAddress);
    const MetaLabsSpace = process.env.REACT_APP_RAFFLE_CONTRACT;
    const payLDVAmount = process.env.REACT_APP_LDV_PAY_AMOUNT;

    let e;
    try {
        var correctPrice = web3.utils.toBN(
            web3.utils.toWei(price, "ether").toString()
        );
    } catch (u) {
        console.log('err', u);
    }

    try {
        let currentBalance = await contract.methods.balanceOf(address).call();
        if(currentBalance < web3.utils.toWei(payLDVAmount, "ether")) {
            return { success: false, type: 'insufficient' }
        }
    } catch (u) {
        console.log('err', u);
    }

    try {
        e = await contract.methods.approve(MetaLabsSpace, correctPrice).estimateGas({
            from: address
        })        
    } catch (u) {
        return { success: false, type: 'estimategas' }
    }
    let d = await web3.eth.getGasPrice();
    let c;
    
    try {
        c = await contract.methods.approve(MetaLabsSpace, correctPrice).send({
            from: address,
            gas: parseInt(e),
            gasPrice: parseInt(1.2 * d),
            maxFeePerGas: null,
        })
    } catch (u) {
        return {success: false, type:"approve"};
    }

    if(c){
        return {success: c.status, type:"approve"};
    }
}

export const payWithLDV = async (raffle_id, address) => {
    if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        window.ethereum.enable();
    } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
    } else {
        window.alert(
            "Non-Ethereum browser detected. You should consider trying MetaMask!"
        );
    }
    var web3 = window.web3;
    
    const MetaLabsSpace = require("./MetaLabsSpace.json");
    const contractAddress = web3.utils.toChecksumAddress(process.env.REACT_APP_RAFFLE_CONTRACT);
    const contract = new web3.eth.Contract(MetaLabsSpace.abi, contractAddress);

    let e;

    try {
        e = await contract.methods.depositLDV(raffle_id).estimateGas({
            from: address
        })        
    } catch (u) {
        return { success: false, type: 'estimategas' }
    }
    let d = await web3.eth.getGasPrice();
    let c;
    
    try {
        c = await contract.methods.depositLDV(raffle_id).send({
            from: address,
            gas: parseInt(e),
            gasPrice: parseInt(1.2 * d),
            maxFeePerGas: null,
        })
    } catch (u) {
        return {success: false, type:"deposit"};
    }

    if(c){
        return {success: c.status, type:"deposit"};
    }
}
