import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import swal from 'sweetalert';
import "./style.scss";
const server_url = process.env.REACT_APP_SERVER_URL;

function Verify() {
    const {token} = useParams();

    useEffect(() => {
        if (token) {            
            axios.post(`${server_url}/email_verify`, {
                token: token 
            }).then(res => {
                console.log("res.data is ", res.data);
                if (res.data.flag === "success") {
                    swal("Login", "Your email is verified successfully.", "success").then(function(isConfirm) {
                        if (isConfirm) {
                            window.location.href = '/';
                        } else {
                          swal("Error", "Your email is not verified successfully.", "error");
                        }
                    });
                }
                else if(res.data.flag === "failed") {
                    swal("Error", "Your account is not found. Please try again!", "error").then(function(isConfirm) {
                        if (isConfirm) {
                            window.location.href = '/';
                        }
                    });                    
                }
            }).catch(err => {
                swal("Error", "Error! Please try again!", "error");
            })
        }
    }, [token])

    return (
        <div className="verify_wrapper">

        </div>
    );
}

export default Verify;