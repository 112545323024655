import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux";
import "./style.scss"
import $ from 'jquery';
import swal from 'sweetalert';
import socketIOClient from "socket.io-client";
const socket_url = process.env.REACT_APP_SOCKET_URL;

const Box3 = () => {
    let textInput = React.createRef();
    var userInfo = useSelector((state) => state.auth);

    useEffect(() => {
        const socket = socketIOClient(socket_url);
        socket.on("receiveText", data => {            
            $(".box3").append(`<div class="message"> <span class="date">${data.user}</span> : <span>${data.text}</span> </div>`);
            var d = $('.box3');
            d.scrollTop(d.prop("scrollHeight"));

            var count = $(".box3").find(".message").length;                
            if (count > 100) {
                $(".box3").find(".message:first").remove();
            }
        });
    }, []);

    useEffect(() => {
        const socket = socketIOClient(socket_url);
        socket.emit("trollListsRequest", { });
        socket.on("trollLists", data => {            
            $(".box3").html('');
            for(var i=0; i<data.length; i++) {    
                var troll = data[i];
                $(".box3").append(`<div class="message"> <span class="date">${troll.user}</span> : <span>${troll.text}</span> </div>`);
            }
            var d = $('.box3');
            d.scrollTop(d.prop("scrollHeight"));
        });
    }, []);

    function handleClick() {
        const socket = socketIOClient(socket_url);
        
        if (userInfo.user.user !== "" && userInfo.user.user) {
            if (textInput.current.value != "") {
                socket.emit("sendText", { text: textInput.current.value, user: userInfo.user.user });
                textInput.current.value = "";
                var d = $('.box3');
                d.scrollTop(d.prop("scrollHeight"));

                var count = $(".box3").find(".message").length;                
                if (count > 100) {
                    $(".box3").find(".message:first").remove();
                }
            }
        }
        else {
            swal("You must login.", "", "info")
        }
    }

    return (
        <div>
            <div className="box-container box3">
            </div>
            <div className="inline">
                <input id="chat" ref={textInput} onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        handleClick();
                    }
                }} className="form-control" />
                <button onClick={handleClick} className="btn btn-main sendBtn">Send</button>
            </div>
        </div>
    )
}

export default Box3