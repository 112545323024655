import React, { useState, useEffect } from "react"
import { Col, Row } from "react-bootstrap"
import "./style.scss"

import piece1 from '../../assets/images/tt.png';
import man1 from '../../assets/images/man1.png';
import man2 from '../../assets/images/man2.png';
import { Button, Modal } from 'antd';
import StripeContainer from '../../components/Stripe/StripeContainer';
import swal from 'sweetalert';
import { payWithETH, approvePayWithLDV, payWithLDV } from "../../web3/web3";
import socketIOClient from "socket.io-client";
import axios from "axios"
import $ from 'jquery';

const socket_url = process.env.REACT_APP_SOCKET_URL;
const server_url = process.env.REACT_APP_SERVER_URL;

const LDV_PAY_AMOUNT = process.env.REACT_APP_LDV_PAY_AMOUNT;
const ETH_PAY_AMOUNT = process.env.REACT_APP_ETH_PAY_AMOUNT;

const Box2 = (props) => {
    const { metamaskConnected, account, setMetamaskConnnected, userId } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [timer1, setTimer1] = useState(28800);
    const [timer2, setTimer2] = useState(28800);
    const [timer3, setTimer3] = useState(28800);
    
    const [raffle1, setRaffle1] = useState(1);
    const [raffle2, setRaffle2] = useState(2);
    const [raffle3, setRaffle3] = useState(3);
    const [history, setHistory] = useState([]);

    const [preview1, setPreview1] = useState('');
    const [preview2, setPreview2] = useState('');
    const [preview3, setPreview3] = useState('');
    
    useEffect(() => {
        const socket = socketIOClient(socket_url);
        socket.on("broadcast_time", data => {
            runTimer(data);
        });

        socket.on("raffle_result", data => {
            alertResult(data);
        });
    }, []);

    useEffect(() => {
        getAllHistories().then((data) => {
            console.log(data);
        });
        
        setTimeout(() => {
            loadPreviewImages();
        }, 1500);
    }, []);


    const alertResult = (data) => {
        if(data.success) {
            if(data.data.winner_email == userId) {
                var message = "You are the winner of " + data.raffle_id + "th raffle!";
                swal("Congratulations!", message, "success");
            }
            else {
                var message = data.data.winner_email + " is the winner of " + data.raffle_id + "th raffle!";
                swal(data.raffle_id + "th Raffle Result: ", message, "info");
            }
            getAllHistories();
        }
        else {
            if(data.data == "No Investers") {
                var message = "Nobody invested in " + data.raffle_id + "th raffle! No Winner!";
                swal(data.raffle_id + "th Raffle Result: ", message, "info");
            }
            else {
                swal(data.raffle_id + "th Raffle Result: ", "Sorry. Error occured unexpectedly. Please try it again.", "info");
            }
        }
    }

    let raffle1_id = 0, raffle2_id = 0, raffle3_id = 0;

    const runTimer = (data) => {
        setTimer1(data.timer.first);
        setTimer2(data.timer.second);
        setTimer3(data.timer.third);

        setRaffle1(data.raffle.first);
        setRaffle2(data.raffle.second);
        setRaffle3(data.raffle.third);
        
        $(".time1").html(changeTime(data.timer.first));
        $(".time2").html(changeTime(data.timer.second));
        $(".time3").html(changeTime(data.timer.third));

        raffle1_id = data.raffle.first;
        raffle2_id = data.raffle.second;
        raffle3_id = data.raffle.third;
    }

    const loadPreviewImages = () => {    
        axios.post(`${server_url}/getProductImages`, {
          raffle1: raffle1_id,
          raffle2: raffle2_id,
          raffle3: raffle3_id
        }).then(res => {  
            if(res.data.raffle1_image) {
              setPreview1(`${server_url}/products/${res.data.raffle1_image}`);
            }
            if(res.data.raffle2_image) {
              setPreview2(`${server_url}/products/${res.data.raffle2_image}`);
            }
            if(res.data.raffle3_image) {
              setPreview3(`${server_url}/products/${res.data.raffle3_image}`);
            }
        }).catch(err => {
            console.log(err)
        })
      }

    const changeTime = (ms) => {
        var seconds = ms;
        var hoursLeft = Math.floor(seconds / 3600);
        var min = Math.floor((seconds - hoursLeft * 3600) / 60);
        var secondsLeft = seconds - hoursLeft * 3600 - min * 60;
        secondsLeft = Math.round(secondsLeft * 100) / 100;
        var answer = hoursLeft < 10 ? "0" + hoursLeft : hoursLeft;
        answer += ":" + (min < 10 ? "0" + min : min);
        answer += ":" + (secondsLeft < 10 ? "0" + secondsLeft : secondsLeft);
        return answer;
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    
    const handleConnectWallet = async () => {
        if (window.ethereum) {
            await window.ethereum.enable();
            setMetamaskConnnected(true);
            window.location.reload();
        }
    };

    const pay_LDV = async (raffle_id) => {
        if(!userId) {
            swal("Error", "You must login.", "error");
            return;
        }
        if(!metamaskConnected) {            
            swal("Error", "You must connect your wallet.", "error").then(function(isConfirm) {
                if (isConfirm) {
                    handleConnectWallet();
                } else {
                  swal("Error", "Your wallet is not connected successfully.", "error");
                }
            });
            return;
        }
        
        await approvePayWithLDV(LDV_PAY_AMOUNT, account).then(
            (data) => {
              console.log("data: ", data);              
              if (data.success == false) {
                if (data.type == "insufficient") {
                    swal(
                        "Sorry",
                        `You don't have enough LDV token now. You need to have at least ${LDV_PAY_AMOUNT} LDV tokens.`,
                        "warning"
                    );
                } else if (data.type == "estimategas") {
                  swal(
                    "Sorry",
                    `Insufficient gas fees to pay with LDV token. Please consider some gas fee!`,
                    "warning"
                  );
                } else if (data.type == "approve") {
                  swal("Sorry", "Error happened while processing, please try again later", "warning");
                }
              }
              else if(data.success) {
                process_LDV_Payment(raffle_id);
              }
            }
        );
    };

    const process_LDV_Payment = async (raffle_id) => {
        await payWithLDV(1, account).then(
            (data) => {
              console.log("data: ", data);              
              if (data.success == false) {
                if (data.type == "estimategas") {
                  swal(
                    "Sorry",
                    `Insufficient gas fees to pay with LDV token. Please consider some gas fee!`,
                    "warning"
                  );
                } else if (data.type == "deposit") {
                  swal("Sorry", "Error happened while processing, please try again later", "warning");
                }
              }
              else if(data.success) {
                savePayment(userId, raffle_id, "LDV");
              }
            }
        );
    }
    const pay_ETH = async (raffle_id) => {
        if(!userId) {
            swal("Error", "You must login.", "error");
            return;
        }
        if(!metamaskConnected) {            
            swal("Error", "You must connect your wallet.", "error").then(function(isConfirm) {
                if (isConfirm) {
                    handleConnectWallet();
                } else {
                  swal("Error", "Your wallet is not connected successfully.", "error");
                }
            });
            return;
        }
        
        await payWithETH(ETH_PAY_AMOUNT, 1, account).then(
            (data) => {
              console.log("data: ", data);              
              if (data.success == false) {
                if (data.type == "estimategas") {
                  swal(
                    "Sorry",
                    `Insufficient gas fees to pay with Ethereum. Please consider the price ${ETH_PAY_AMOUNT}Eth, and some gas fee!`,
                    "warning"
                  );
                } else if (data.type == "deposit") {
                  swal("Sorry", "Error happened while processing, please try again later", "warning");
                }
              }
              else if(data.success) {
                savePayment(userId, raffle_id, "ETH");
              }
            }
        );
    };

    const savePayment = (email, raffle_id, payment_type) => {
        axios.post(`${server_url}/savePayment`, {
            email: email,
            raffle_id: raffle_id,
            payment_type: payment_type
        }).then(res => {
            if (res.data.success) {                
                swal("Success", "Congratulations! You are recorded in the raffle!", "success");
            }            
            else {
                swal("Error", "Error occured unexpectedly. Please try it again!", "error");
            }
        }).catch(err => {
            swal("Error", "Error occured unexpectedly. Please try it again!", "error");
        })        
    }

    const getAllHistories = async () => {
        axios.get(`${server_url}/getAllHistories`, {
        }).then(res => {
            if (res.data.success) {
                $(".raffle-history").html('');
                for(let i=0; i<res.data.data.length; i++) {
                    let item = res.data.data[i];
                    $(".raffle-history").append(`<h4> ${item.raffle_id}th Raffle Winner: ${item.winner_email}</h4>`);
                }                
            }            
            else {
                console.log("Error while getting raffle history.");
            }
        }).catch(err => {
            console.log("Error while getting raffle history.");
        })        
    }

    return (
        <>
            <div className="box-container">
                <div className="box2">
                    <Row>
                        <div className="dis-flex _center">
                            <div className="w50">
                                <img className="w100px" src={man2} />
                            </div>
                            <div className="w50">
                                <img className="w100px" src={man1} />
                            </div>
                        </div>
                        <Col lg="4" sm="12">
                            <div className="time">
                                <h3 className={'raffle-id-text '  + (timer1 < 60 ? 'red':'')}>Raffle ID: {raffle1}</h3>
                                <div className={'border time1 ' + (timer1 < 60 ? 'red':'')}>00:04:59</div>
                                <img src={preview1 ? preview1 : piece1} />
                                <div className="coin col-lg-12 p0">
                                    <button className="coinback-left" onClick={() => pay_LDV(raffle1)}> LDV </button>
                                    <button className="coinback-left" onClick={() => pay_ETH(raffle1)}> ETH </button>
                                </div>
                                <div className="coin col-lg-12 p0">
                                    <button className="coinback-left" onClick={showModal} disabled> USD </button>
                                    <button className="coinback-left"> BTC </button>
                                </div>
                            </div>
                        </Col>
                        <Col lg="4" sm="12">
                            <div className="time">
                                <h3 className={'raffle-id-text '  + (timer2 < 60 ? 'red':'')}>Raffle ID: {raffle2}</h3>
                                <div className={'border time2 ' + (timer2 < 60 ? 'red':'')}>00:04:59</div>
                                <img src={preview2 ? preview2 : piece1} />
                                <div className="coin col-lg-12 p0">
                                    <button className="coinback-middle" onClick={() => pay_LDV(raffle2)}> LDV </button>
                                    <button className="coinback-middle" onClick={() => pay_ETH(raffle2)}> ETH </button>
                                </div>
                                <div className="coin col-lg-12 p0">
                                    <button className="coinback-middle" onClick={showModal} disabled> USD </button>
                                    <button className="coinback-middle"> BTC </button>
                                </div>
                            </div>
                        </Col>
                        <Col lg="4" sm="12">
                            <div className="time">
                                <h3 className={'raffle-id-text '  + (timer3 < 60 ? 'red':'')}>Raffle ID: {raffle3}</h3>
                                <div className={'border time3 ' + (timer3 < 60 ? 'red':'')}>00:04:59</div>
                                <img src={preview3 ? preview3 : piece1} />
                                <div className="coin col-lg-12 p0">
                                    <button className="coinback-right" onClick={() => pay_ETH(raffle3)}> LDV </button>
                                    <button className="coinback-right" onClick={() => pay_ETH(raffle3)}> ETH </button>
                                </div>
                                <div className="coin col-lg-12 p0">
                                    <button className="coinback-right" onClick={showModal} disabled> USD </button>
                                    <button className="coinback-right"> BTC </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="box-container">
                <div className="box2 raffle-history">                    
                </div>                
            </div>
            <Modal 
                title="Pay with Credit Card"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel} 
                footer={[
                    <Button key="back" onClick={handleCancel}>
                      Return
                    </Button>,
                ]}
            >
                <StripeContainer />
            </Modal>
        </>
    )
}

export default Box2