import React from "react"
import "./style.scss"
import { BsTwitter, BsInstagram, BsLinkedin, BsYoutube } from 'react-icons/bs';

const Footer = () => {
    return (
        <footer role="contentinfo">
            <ul>
                <li className="no-link"><a style={{ color: '#ff4494' }} href="https://meta-labs.space/retailer">Retailer</a></li>
                <li><a style={{ color: '#51ffb4' }} href="https://meta-labs.space/contact">Contact</a></li>                
                <li className="no-link"><a style={{ color: '#f5ff4d' }} href="https://forum.meta-labs.space">Forum</a></li>
            </ul>

            <ul>
                <li className="no-link"><a href="https://twitter.com/MetaLabsSpace"> <BsTwitter /> </a></li>
                <li><a href="https://www.instagram.com/meta.labs.space/"> <BsInstagram /> </a></li>
                <li className="no-link"><a href="http://www.linkedin.com/in/MetaLabsSpace"> <BsLinkedin /> </a></li>
                <li className="no-link"><a href="https://www.youtube.com/channel/UCCOypYbqLM03QBYP7-2HCGQ"> <BsYoutube /> </a></li>
            </ul>
        </footer >
    )
}

export default Footer