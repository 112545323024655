import React, { useEffect, useState } from 'react';
import { Provider } from "react-redux";
import store from "./redux/store";
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import ScrollToTop from "./pages/scrollToTop";
import Design from "./pages/design"
import Verify from './pages/Verify';
import Blank from './pages/Blank'
import Dashboard from './pages/Dashboard'
import MainLayout from './layout/MainLayout'
import Web3 from "web3";
import "./App.scss";
import './assets/libs/boxicons-2.1.1/css/boxicons.min.css';
import './scss/admin_main.scss';

const App = () => {
  const [metamaskConnected, setMetamaskConnnected] = useState(false);
  const [account, setAccount] = useState();
  const [networkId, setNetworkId] = useState();
  const [isMetamask, setIsMetamask] = useState(true);

  useEffect(() => {
    loadWeb3().then((data) => {
      if (data != false) {
        loadBlockchainData();
      }
    });
  }, []);

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      // window.alert(
      //   "Non-Ethereum browser detected. You should consider trying MetaMask!"
      // );
      setIsMetamask(false);
      return false;
    }
  };

  const loadBlockchainData = async () => {
    const web3 = window.web3;
    const accounts = await web3.eth.getAccounts();
    const networkId = await web3.eth.net.getId();
    setNetworkId(networkId);

    if (accounts.length == 0) {
      setMetamaskConnnected(false);
    } else {
      setMetamaskConnnected(true);
      setAccount(accounts[0]);
    }

    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) setAccount(accounts[0]);
      else setAccount();
    });
    window.ethereum.on("networkChanged", (networkId) => {
      setNetworkId(networkId);
    });
  };

  return (
    <Provider store={store}>
      {networkId != 5 && metamaskConnected && (
        <div className="network-err-msg">
          <h1>To use Meta Labs Space, please switch to Ethereum Goerli Test Network.</h1>
        </div>
      )}
      {!isMetamask && (
        <div className="network-err-msg">
          <h1>Non-Ethereum browser detected. You should consider trying MetaMask!</h1>
        </div>
      )}
      <div className="network-err-msg">
        <h4>Hi Earthlings. 🖖 We beam to you construction of our website as it undergoes live development.</h4>        
        <h4>We hope to deliver the site by June 2023 so please check back then. That doesn’t mean you aren’t welcome to dance around our site hopefully in your tinfoil cap. IDK. </h4>
        <h4>As you earthlings would say, “whatever floats your boat.” Enough beamed. Until June 2023. </h4>
        <h4>-We come in Peace. 👽</h4>
      </div>      
      <BrowserRouter>
        <ScrollToTop />
        <div>
          <Routes>
            <Route 
              exact 
              path="/" 
              element={
                <Design 
                  metamaskConnected={metamaskConnected}
                  setMetamaskConnnected={setMetamaskConnnected}
                  account={account}
                />
              } 
            />
            <Route exact path='/player/verify/:token' element={<Verify />} />
            <Route path="/admin" element={<MainLayout />}>
              <Route index element={<Dashboard />} />
              <Route path="orders" element={<Blank />} />
              <Route path="products" element={<Blank />} />
              <Route path="customers" element={<Blank />} />
              <Route path="settings" element={<Blank />} />
              <Route path="stats" element={<Blank />} />
            </Route>
          </Routes>
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;


// dashboard, payments, raffles, products, users,
