import {
  LOGIN_SUCCESS,
  LOGIN_OUT
} from "../shared/ActionTypes.js";
import swal from 'sweetalert';


export const loginsuccess = (value) => {
  return (dispatch) => {
    dispatch({
      type: LOGIN_SUCCESS,
      payload: value
    })
  }
}

export const logout = () => {
  return (dispatch) => {
    swal("Logout", "logout", "info");
    dispatch({
      type: LOGIN_OUT
    })
  }
}

